import React from "react"
import Layout from "../components/layout/index"
import HeroImageSection from "../components/events/heroImageSection"

// import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"

export default function Courses() {
  //   const classes = useStyles()

  return (
    <Layout>
      <Grid container justify="center" style={{ backgroundColor: "#FBFBFB" }}>
        <Grid item xs={12}>
          <HeroImageSection />
        </Grid>
      </Grid>
    </Layout>
  )
}

// export default IndexPage

// const useStyles = makeStyles(theme => ({}))
