import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, Typography, useMediaQuery } from "@material-ui/core"

function HeroImageSection() {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              className={classes.heading}
              style={{ fontSize: isMobile && "36px" }}
            >
              Events
            </Typography>
            <Typography
              className={classes.subHeading}
              style={{ fontSize: isMobile && "20px" }}
            >
              Coming Soon !!!!
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default HeroImageSection

const useStyles = makeStyles(theme => ({
  container: {
    // backgroundImage: `url(${coursesHeroImage})`,
    minHeight: "360px",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  innerContainer: {
    minHeight: "360px",
    width: "100%",
    background: "rgba(63, 92, 120, 0.46)",
    display: "flex",
    alignItems: "center",
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "55px",
    lineHeight: "64px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "151.69%",
    color: "#FBFBFF",
    letterSpacing: "0.045em",
    marginTop: "20px",
    marginLeft: "18px",
    marginRight: "20px",
  },

  subHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "36px",
    lineHeight: "64px",
    color: "#FFFFFF",
    textAlign: "center",
  },
}))
